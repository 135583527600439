import Card from "@material-ui/core/Card";
import { Link } from "gatsby";
import React from "react";
import RightArrow from "../../assets/Icons/go.svg";
import Button from "../../components/Button";
import "./PlainMiniCard.scss";

const PlainMinicard = (props) => {
  return (
    <Card className="plain-minicard-area appsgridshadow">
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={props.PlainMiniCardData.route}
      >
        <div className="plain-minicard-content">
          {props.PlainMiniCardData.content}
        </div>
        <div className="plain-minicard-subTitle">
          {props.PlainMiniCardData.subTitle}
        </div>
        <div className="plain-minicard-subContent">
          {props.PlainMiniCardData.subContent}
        </div>
        {/* <Tooltip title={props.tip} placement="bottom"> */}
        <Button
          icon={
            !props.PlainMiniCardData.variant && (
              <img
                className="effect-btn-svgicon"
                src={RightArrow}
                alt="click"
              />
            )
          }
          customClassName={props.PlainMiniCardData.customClassName}
          color="primary"
          tip={props.PlainMiniCardData.label}
          tooltip="yes"
          label={props.PlainMiniCardData.label}
          routepath={props.PlainMiniCardData.route}
        />
        {/* </Tooltip> */}
      </Link>
    </Card>
  );
};

export default PlainMinicard;
