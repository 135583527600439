import Card from "@material-ui/core/Card";
import React from "react";
import "./QuotedMiniCard.scss";

const QuotedMiniCard = (props) => {
  return (
    <Card className="card-area p-20 appsgridshadow mt-34">
      <div className="quoted-title-area">{props.QuotedMiniCardData.title}</div>
      <div className="quoted-italic-area">
        {props.QuotedMiniCardData.italicContent}
      </div>
      <div className="quoted-content-area">
        {props.QuotedMiniCardData.content}
      </div>
      <div className="quoted-footer-area">
        <div className="quoted-minicard-black-line"></div>
        {props.QuotedMiniCardData.footerContent}
      </div>
    </Card>
  );
};

export default QuotedMiniCard;
