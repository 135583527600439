import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import React from "react";
import RightArrow from "../../assets/Icons/go.svg";
import quote from "../../assets/Images/WhatWeDo/quote.svg";
import Button from "../../components/Button";
import Minicard from "../../components/MiniCard";
import PlainMinicard from "../../components/PlainMiniCard";
import QuotedMiniCard from "../../components/QuotedMiniCard";
import "./TextImageQuotesCardView.scss";

const TextImageQuotesCardView = (props) => {
  return (
    <div className="body-container">
      {props.TextImageQuotesCardViewData.map((item, index) => (
        <React.Fragment>
          <div className={item.heading.cardNo === "odd" ? "odd-bg" : "even-bg"}>
            <Link
              target="_blank"
              style={{ textDecoration: "none", color: "black" }}
              to={item.card1.route}
            >
              <h2 className="heading-style">{item.heading.heading}</h2>
            </Link>
            <Link
              target="_blank"
              style={{ textDecoration: "none", color: "black" }}
              to={item.card1.route}
            >
              <p className="font-16">{item.heading.content1}</p>
            </Link>
            <div className="mbl-button">
              <Button
                icon={
                  !item.card1.variant && (
                    <img
                      className="effect-btn-svgicon"
                      src={RightArrow}
                      alt="click"
                    />
                  )
                }
                customClassName={item.card1.customClassName}
                color="primary"
                tip={item.card1.label}
                tooltip="yes"
                label="Learn More"
                routepath={item.card1.route}
              />
            </div>
            <Grid
              key={index}
              className="mt-10"
              container
              direction="row"
              spacing={3}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className="mobile-hide card crd-spc"
              >
                <div className="plain-title">{item.card1.title}</div>
                <br />
                <PlainMinicard PlainMiniCardData={item.card1} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className="plain-title">{item.card2.heading}</div> <br />
                {/* <StaticMiniview StaticMiniviewData={item.card2} /> */}
                <Link
                  target="_blank"
                  style={{ textDecoration: "none", color: "black" }}
                  to={`/${item.card2.Type}/${item.card2.slug}`}
                >
                  <Minicard
                    MiniviewHeading={item.card2.title}
                    MiniviewContent={item.card2.content}
                    MiniviewImage={item.card2.image}
                    imgAlt={item.card2.imageAlt}
                    WhitePaperFooterLabel={item.card2.type}
                    Slug={item.card2.slug}
                    Type={item.card2.type}
                  />
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                style={{ position: "relative" }}
                className="card crd-spc"
              >
                <div className="plain-title quote-star-what mt-11">
                  <img alt={quote} src={quote} />
                </div>
                <br />
                {/* <div className="plain-title quote-star-what"><img src={quote} /></div> */}
                <QuotedMiniCard QuotedMiniCardData={item.card3} />
              </Grid>{" "}
              <br />
              <div className="mt-20"></div>
            </Grid>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default TextImageQuotesCardView;
