import React, { useState, useEffect } from "react";
import DataService from "../services/api/data_services";

import Layout from "../components/layouts/layout";
import Typography from "@material-ui/core/Typography";
import Infoview from "../templates/InfoView";

import BackArrowIcon from "../assets/Images/accordian.svg";
import Button from "../components/Button";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
import BannerBgImage from "../assets/Images/ProductDevelopment/ProductDevelopment-banner.png";
//Mini Image Banner
import MiniImageBanner from "../templates/MiniImageBanner";
import MiniImgBnrBgImg from "../assets/Images/HowWeDoIt/contactus_250px.png";

//Text Image Quotes Card View
import TextImageQuotesCardView from "../templates/TextImageQuotesCardView";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";

//Mini Image Banner1
import MiniImageBanner1 from "../templates/MiniImageBanner1";
import MiniImageBannerBgImg from "../assets/Images/WhatWeDo/who_we_work_with.png";
import MiniImageBannerBgImg12 from "../assets/Images/HowWeDoIt/who_we_work_with.png";

import { BASE_SITE_URL } from "../shared/constants";
//Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import ogimage from "../assets/Images/ogImages/OG_ProductDeve.jpg";

const ProductDevelopment = () => {
  const [clientStoryMbl, setClientStoryMbl] = useState([]);
  const [clientStoryWeb, setClientStoryWeb] = useState([]);
  const [clientStoryFull, setClientStoryFull] = useState([]);
  const [clientStoryMVP, setClientStoryMVP] = useState([]);
  const [clientStoryProduct, setClientStoryProduct] = useState([]);
  const [clientStoryScaling, setClientStoryScaling] = useState([]);
  const [clientStoryCode, setClientStoryCode] = useState([]);
  const [clientStoryDigital, setClientStoryDigital] = useState([]);
  const [clientStoryMachine, setClientStoryMachine] = useState([]);

  useEffect(() => {
    DataService.getData(
      `admin/api/what-we-do?page=whatwedo&type=Client Story&component=mini_view&service_type=PD - Mobile Application Development`
    ).then((response) => {
      setClientStoryMbl([...clientStoryMbl, ...response]);
    });
    DataService.getData(
      `admin/api/what-we-do?page=whatwedo&type=Client Story&component=mini_view&service_type=PD - Web Application Development	`
    ).then((response) => {
      setClientStoryWeb([...clientStoryWeb, ...response]);
    });
    DataService.getData(
      `admin/api/what-we-do?page=whatwedo&type=Client Story&component=mini_view&service_type=PD - Custom Software Development	`
    ).then((response) => {
      setClientStoryFull([...clientStoryFull, ...response]);
    });
    DataService.getData(
      `admin/api/what-we-do?page=whatwedo&type=Client Story&component=mini_view&service_type=PD - MVP Development`
    ).then((response) => {
      setClientStoryMVP([...clientStoryMVP, ...response]);
    });
    DataService.getData(
      `admin/api/what-we-do?page=whatwedo&type=Client Story&component=mini_view&service_type=PD - Product Designing %26 Prototyping`
    ).then((response) => {
      setClientStoryProduct([...clientStoryProduct, ...response]);
    });
    DataService.getData(
      `admin/api/what-we-do?page=whatwedo&type=Client Story&component=mini_view&service_type=PD - Product Scaling`
    ).then((response) => {
      setClientStoryScaling([...clientStoryScaling, ...response]);
    });
    DataService.getData(
      `admin/api/what-we-do?page=whatwedo&type=Client Story&component=mini_view&service_type=PD - Code %26 UI/UX Audit`
    ).then((response) => {
      setClientStoryCode([...clientStoryCode, ...response]);
    });
    DataService.getData(
      `admin/api/what-we-do?page=whatwedo&type=Client Story&component=mini_view&service_type=PD - Digital Transformation`
    ).then((response) => {
      setClientStoryDigital([...clientStoryDigital, ...response]);
    });
    DataService.getData(
      `admin/api/what-we-do?page=whatwedo&type=Client Story&component=mini_view&service_type=PD - Machine Learning %26 AI`
    ).then((response) => {
      setClientStoryMachine([...clientStoryMachine, ...response]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * TextImageQuotesCardView
   */
  const TextImageQuotesCardViewData = [
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">Mob</span>
            ile Application Development{" "}
          </>
        ),
        width: "56px",
        cardNo: "odd",
        content1:
          "Our expertise in building robust, multi-platform mobile applications ensures a consistent user experience for iOS, Android or Windows devices and platforms. We’ve got the right team to build the right solution for your business.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Mobile App Experience:</b> Our Mobile App experience ensures your
            app is delivered as agreed, on time, in budget.
            <br /> <b>Security:</b> High priority, robust security and testing
            processes prevents costly security breaches in your Mobile App.
            <br />
            <b> Compliance:</b> Our Apple/Google App Store expertise ensures
            your Mobile App is built fast and accepted first time.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Create next-generation mobile applications to increase user engagement and upscale your business",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/mobile-application-development",
      },
      card2: {
        image: clientStoryMbl.length > 0 ? clientStoryMbl[0].og_image : "",
        imageAlt:
          clientStoryMbl.length > 0 ? clientStoryMbl[0].imageAltTxt : "",
        title: clientStoryMbl.length > 0 ? clientStoryMbl[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryMbl.length > 0 &&
          clientStoryMbl[0].meta_description !== null
            ? clientStoryMbl[0].meta_description
            : "",
        type: clientStoryMbl.length > 0 ? clientStoryMbl[0].slug_type : "",
        slug: clientStoryMbl.length > 0 ? clientStoryMbl[0].slug : "",
        Type: clientStoryMbl.length > 0 ? clientStoryMbl[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product",
        italicContent: "Mobile App Dev for Automative Sales App",
        content: "Founder, Bumper UK",
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">Web</span>{" "}
            Application Development{" "}
          </>
        ),
        cardNo: "even",
        width: "52px",
        content1:
          "We create custom web applications that are designed to meet business challenges, promote growth and achieve your business goals. We use adaptive web applications and keep the end-users in mind to simplify complex business workflows.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Any App Stage:</b> Convenient range of services covering all app
            stages - research, launch, growth or maturity.
            <br />
            <b>Quality: </b>Highly trained staff, ISO 9001 standards and use of
            best practices guarantees a high-quality Web App. <br />
            <b>Protect:</b> Coding and other best practices ensure your App can
            be extended without extensive re-working.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Build an advanced web application to support various aspects of your business functions",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/web-application-development",
      },
      card2: {
        image: clientStoryWeb.length > 0 ? clientStoryWeb[0].og_image : "",
        imageAlt:
          clientStoryWeb.length > 0 ? clientStoryWeb[0].imageAltTxt : "",
        title: clientStoryWeb.length > 0 ? clientStoryWeb[0].title : "",
        heading: "EXPLORE",
        content:
          clientStoryWeb.length > 0 &&
          clientStoryWeb[0].meta_description !== null
            ? clientStoryWeb[0].meta_description
            : "",
        type: clientStoryWeb.length > 0 ? clientStoryWeb[0].slug_type : "",
        slug: clientStoryWeb.length > 0 ? clientStoryWeb[0].slug : "",
        Type: clientStoryWeb.length > 0 ? clientStoryWeb[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title: "Digiryte was on time and has competitive prices.",
        italicContent: "Web application Design for Car Dealership",
        content: "Director, Chanscars",
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">Full</span>{" "}
            Product Development{" "}
          </>
        ),
        cardNo: "odd",
        width: "45px",
        content1:
          "We provide quick ideation, prototyping and MVP development to ensure your product meets the needs of the market. We can help you scale and expand your business to a new level at every stage of your product development cycle.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Convenience:</b> Design, MVP, Build, Scale. With experts in all
            areas, select all services or just the ones you need.
            <br /> <b>Audit Trail:</b> Technical, Project and Requirements
            documents maintained so you have a complete audit trail. <br />
            <b>One-stop-shop:</b> Save time dealing with multiple companies.
            Access to all related services in one place.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Create a next-generation mobile application to increase user engagement and upscale your business",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/full-product-development",
      },
      card2: {
        image: clientStoryFull.length > 0 ? clientStoryFull[0].og_image : "",
        imageAlt:
          clientStoryFull.length > 0 ? clientStoryFull[0].imageAltTxt : "",
        title: clientStoryFull.length > 0 ? clientStoryFull[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryFull.length > 0 &&
          clientStoryFull[0].meta_description !== null
            ? clientStoryFull[0].meta_description
            : "",
        type: clientStoryFull.length > 0 ? clientStoryFull[0].slug_type : "",
        slug: clientStoryFull.length > 0 ? clientStoryFull[0].slug : "",
        Type: clientStoryFull.length > 0 ? clientStoryFull[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title: "If I could describe them in one word, it’d be cooperative.",
        italicContent: "Flutter Development for Home Services Marketplace App",
        content: "Founder, Home Services Marketplace",
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
  ];

  /**
   * TextImageQuotesCardView
   */
  const TextImageQuotesCardViewData1 = [
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">MVP</span>{" "}
            Development
          </>
        ),
        cardNo: "odd",
        width: "47px",
        content1:
          "Before you move on to create a new digital product, you should definitely consider launching a Minimum Viable Product (MVP) to assess your business risk. Use MVP design to lower the cost of market testing and use real-time feed back to improve your product.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Short Cuts:</b> Refine and speed up MVP design by utilising our
            expert Business, Growth & Innovation team. <br />
            <b>Reduce Risk:</b> Analyse your business hypotheses. Build MVP to
            validate the riskiest elements. Stop wasted effort.
            <br /> <b>Validation:</b> Identify KPIs that will validate your idea
            using your MVP. Learn and refine to get the perfect App.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Quickly test your business hypothesis for the new app you want to build for your product or services.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/mvp-development",
      },
      card2: {
        image: clientStoryMVP.length > 0 ? clientStoryMVP[0].og_image : "",
        imageAlt:
          clientStoryMVP.length > 0 ? clientStoryMVP[0].imageAltTxt : "",
        title: clientStoryMVP.length > 0 ? clientStoryMVP[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryMVP.length > 0 &&
          clientStoryMVP[0].meta_description !== null
            ? clientStoryMVP[0].meta_description
            : "",
        type: clientStoryMVP.length > 0 ? clientStoryMVP[0].slug_type : "",
        slug: clientStoryMVP.length > 0 ? clientStoryMVP[0].slug : "",
        Type: clientStoryMVP.length > 0 ? clientStoryMVP[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "They promised a functioning Web application skeleton but delivered something even better.",
        italicContent: "AI Dating Software Dev for Production Company",
        content: (
          <div>
            Former Series Producer,
            <br /> Production Company
          </div>
        ),
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">Pro</span>
            duct Designing & Prototyping{" "}
          </>
        ),
        cardNo: "even",
        width: "50px",
        content1:
          "Good product design creates a visual language that drives user engagement, making your product easier to market. We design and develop innovative app prototypes, to help demonstrate the product-market fit.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Results focus:</b> Our UX/UI Designers are trained to deliver
            visuals that are engaging and intuitive.
            <br />
            <b>Flexible Pricing Models:</b> Choose a pricing model to suit your
            needs. Time & Material, Fixed Cost, or Hybrid.
            <br />
            <b>Collaboration:</b> Our platform enables 2-way feedback for speedy
            designs that you can clearly visualize.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Create a product with user-friendly UI/UX to present to your potential investors.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/product-designing-and-prototyping",
      },
      card2: {
        image:
          clientStoryProduct.length > 0 ? clientStoryProduct[0].og_image : "",
        imageAlt:
          clientStoryProduct.length > 0
            ? clientStoryProduct[0].imageAltTxt
            : "",
        title:
          clientStoryProduct.length > 0 ? clientStoryProduct[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryProduct.length > 0 &&
          clientStoryProduct[0].meta_description !== null
            ? clientStoryProduct[0].meta_description
            : "",
        type:
          clientStoryProduct.length > 0 ? clientStoryProduct[0].slug_type : "",
        slug: clientStoryProduct.length > 0 ? clientStoryProduct[0].slug : "",
        Type: clientStoryProduct.length > 0 ? clientStoryProduct[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "Digiryte walks us through the process from an educational perspective in a way that really makes us feel cared about.",
        italicContent: "Interactive Web & App Dev for Business Dev Company",
        content: "CEO, Direct Opportunities Network",
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">Pro</span>
            duct Scaling
          </>
        ),
        cardNo: "odd",
        width: "50px",
        content1:
          "In order to scale up your business, you should not only respond to user feedback and suggestions but also anticipate user needs in order to increase market share. Scaling involves a number of small but significant changes that are added to the product feature in order to thrive in an ever-evolving market.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Agility:</b> Scale quickly. Access to teams with diverse,
            multiple skill sets coupled with robust recruitment processes.
            <br />
            <b>Data Security:</b> With ISO 27001 accreditation, you can be
            assured we manage all your information in a secure manner.
            <br /> <b>Technology Experts:</b> Our team know the factors that
            affect product scaling but still deliver you a quality product.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Add new features and upgrade your existing app to capture new markets.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/product-scaling",
      },
      card2: {
        image:
          clientStoryScaling.length > 0 ? clientStoryScaling[0].og_image : "",
        imageAlt:
          clientStoryScaling.length > 0
            ? clientStoryScaling[0].imageAltTxt
            : "",
        title:
          clientStoryScaling.length > 0 ? clientStoryScaling[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryScaling.length > 0 &&
          clientStoryScaling[0].meta_description !== null
            ? clientStoryScaling[0].meta_description
            : "",
        type:
          clientStoryScaling.length > 0 ? clientStoryScaling[0].slug_type : "",
        slug: clientStoryScaling.length > 0 ? clientStoryScaling[0].slug : "",
        Type: clientStoryScaling.length > 0 ? clientStoryScaling[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "They are easy to reach, supportive, and quick at responding and solving any issues.",
        italicContent: "Web application Dev for Northern Neonatal Network",
        content: (
          <div>
            Clinical Lead, Northern <br /> Neonatal Network
          </div>
        ),
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
  ];

  /**
   * TextImageQuotesCardView
   */
  const TextImageQuotesCardViewData2 = [
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">Cod</span>e & UX
            Audit
          </>
        ),
        cardNo: "odd",
        width: "51px",
        content1:
          "Identify security flaws by auditing your software’s source code and UX. This step helps you avoid any technical problems and keep your digital product in good condition.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Project Rescue:</b> App Project in distress? We’ll audit it and
            recommend accordingly so it gets you back on track.
            <br /> <b>Due diligence:</b> Buying a business? We’ll audit any
            software to identify flaws and save you costly re-writes.
            <br />
            <b>Specification:</b> Use the audit to build a specification, RFI or
            RFP. Identify your requirements and get consistent proposals.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Conduct a customised audit for your web or mobile application’s code and UI/UX.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/code-ux-audit",
      },
      card2: {
        image: clientStoryCode.length > 0 ? clientStoryCode[0].og_image : "",
        imageAlt:
          clientStoryCode.length > 0 ? clientStoryCode[0].imageAltTxt : "",
        title: clientStoryCode.length > 0 ? clientStoryCode[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryCode.length > 0 &&
          clientStoryCode[0].meta_description !== null
            ? clientStoryCode[0].meta_description
            : "",
        type: clientStoryCode.length > 0 ? clientStoryCode[0].slug_type : "",
        slug: clientStoryCode.length > 0 ? clientStoryCode[0].slug : "",
        Type: clientStoryCode.length > 0 ? clientStoryCode[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "I can’t fault them. Everything was done efficiently, quickly, and politely.",
        italicContent: "Web application Redesign for Prenatal Care Company",
        content: (
          <div>
            Director, Professional <br /> Antenatal Services
          </div>
        ),
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">Dig</span>
            ital Transformation
          </>
        ),
        cardNo: "even",
        width: "40px",
        content1:
          "Transform your traditional business model and give it a new digital identity to adapt to changing circumstances. At Digiryte, we help you with your digital transition to build a product for the future.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Experience:</b> Trust us to deliver your Digital Transformation
            project. We’ve done it extensively for many others!!
            <br /> <b>Legacy Systems: </b> We audit Legacy Systems and include
            new needs to ensure they are transformed efficiently.
            <br />
            <b>Satisfy Stakeholders:</b> Our services help you satisfy the needs
            of all your team - Management, Finance, IT etc
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Take your business to the digital world and future proof it, with advanced digital solutions.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/digital-transformation",
      },
      card2: {
        image:
          clientStoryDigital.length > 0 ? clientStoryDigital[0].og_image : "",
        imageAlt:
          clientStoryDigital.length > 0
            ? clientStoryDigital[0].imageAltTxt
            : "",
        title:
          clientStoryDigital.length > 0 ? clientStoryDigital[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryDigital.length > 0 &&
          clientStoryDigital[0].meta_description !== null
            ? clientStoryDigital[0].meta_description
            : "",
        type:
          clientStoryDigital.length > 0 ? clientStoryDigital[0].slug_type : "",
        slug: clientStoryDigital.length > 0 ? clientStoryDigital[0].slug : "",
        Type: clientStoryDigital.length > 0 ? clientStoryDigital[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "Digiryte’s expertise with the technology helped speed up the process.",
        italicContent:
          "Web application Development for Healthcare Management Company",
        content: "Founder, MedzDigital",
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
    {
      heading: {
        heading: (
          <>
            <span className="red-line3letter red-3letter-mbl">Mac</span>
            hine Learning
          </>
        ),
        cardNo: "odd",
        width: "52px",
        content1:
          "With advanced analytics services, make insight-led decisions for your business. If your business is sitting on a mountain of data, let us help you unlock it’s true potential for your business.",
      },
      card1: {
        title: "KEY BENEFITS TO YOU",
        content: (
          <div>
            <b>Subject Expertise:</b> Our Machine Learning experts know what’s
            needed. Use them, Learn, Save time and effort. <br />
            <b>Robust Testing:</b> Function, Network, Speed testing and more.
            Our process means your App performs as required. <br />
            <b>Best Practice:</b> Using best practices in Machine Learning, you
            can be sure it can scale and expand efficiently.
          </div>
        ),
        subTitle: <div style={{ color: "#db4435" }}>If you need to:</div>,
        subContent:
          "Improve your decision-making skills by analysing and using captured data to your advantage.",
        label: "Learn More",
        customClassName: "effect-btn1 no-padding-left mt-20",
        route: "/machine-learning",
      },
      card2: {
        image:
          clientStoryMachine.length > 0 ? clientStoryMachine[0].og_image : "",
        imageAlt:
          clientStoryMachine.length > 0
            ? clientStoryMachine[0].imageAltTxt
            : "",
        title:
          clientStoryMachine.length > 0 ? clientStoryMachine[0].meta_title : "",
        heading: "EXPLORE",
        content:
          clientStoryMachine.length > 0 &&
          clientStoryMachine[0].meta_description !== null
            ? clientStoryMachine[0].meta_description
            : "",
        type:
          clientStoryMachine.length > 0 ? clientStoryMachine[0].slug_type : "",
        slug: clientStoryMachine.length > 0 ? clientStoryMachine[0].slug : "",
        Type: clientStoryMachine.length > 0 ? clientStoryMachine[0].type : "",
        footerLabel: "CASE STUDY",
      },
      card3: {
        title:
          "They promised a functioning website skeleton but delivered something even better.",
        italicContent: "Web Dev & Automation for E-liquid Subscription Service",
        content: (
          <div>
            Former Series Producer, <br /> Production Company{" "}
          </div>
        ),
        footerContent: (
          <div
            style={{ display: "flex", fontSize: "10px", alignItems: "center" }}
          >
            <div>Authentic client review from</div>
            <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
              <img src={clutchLogo} alt="Clutch" height="50px" />
            </div>
          </div>
        ),
      },
    },
  ];
  const MiniImageBanner1Data1 = {
    title: (
      <span>
        Hire Remote <br /> <span className="black-line3letter">Tea</span>ms
      </span>
    ),
    titleClass: "mini-image-banner-title-area3 min-title-header",
    image: MiniImageBannerBgImg,
    altTxt: "Hire Remote Teams",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/hire-remote-teams",
    underline: false,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };

  const MiniImageBanner3Data = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us to Estimate Your Product",
    HoverLinkContent: "Contact Us",
    NearHoverLinkContent: " To ",
    PlainContent: "Estimate Your Product",
    route: "/contact-us",
  };
  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg12,
    tag: "h2",
    altTxt: "Who we work with",
    btnClassName: "takelook-image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    tip: "TAKE A LOOK",
    tooltip: "yes",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    underlineClass: "black-line",
  };
  /**
   * Infoview
   */

  const InfoviewData = {
    color: "primary",
    width: "101px",
    InfoviewTitle: (
      <>
        {" "}
        Custom software solutions to optimise your{" "}
        <span className="red-line3letter red-3letter-mbl red-3letter-mbl">
          bus
        </span>
        iness
      </>
    ),
    InfoviewContent: (
      <>
        At Digiryte, we help you create digital products from simple
        customisations to full-cycle software development. Our expertise lies in
        building experiences for our end users who depend on mobile or web
        applications. If you want to develop a business to sustain the
        challenges of the future, you can count on us to help you innovate and
        grow. Choose from a range of services to meet your business needs.
      </>
    ),
  };
  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: (
      <>
        Product <br /> Development
      </>
    ),
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Team Augmentation",
          link: "/team-augmentation",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Digiryte Pods",
          link: "/digiryte-pods",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Product Development",
          link: "/product-development",
        },
      ],
    },
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };
  return (
    <Layout
      seo={{
        title: "Product Development | Digiryte",
        ogtitle: "Product Development | Digiryte",
        description:
          "Digiryte assembles dedicated development teams shaped around your vision, leveraging access to top Indian talent under diligent strategic guidance.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/product-development`,
        keywords:
          "product development, dedicated development teams, Indian talent, strategic guidance, Digiryte",
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="mt-70">
        <TextImageQuotesCardView
          TextImageQuotesCardViewData={TextImageQuotesCardViewData}
        />
      </div>
      <div className="mt-70">
        <MiniImageBanner miniImageBannerData={MiniImageBanner3Data} />
      </div>
      <div className="mt-70">
        <TextImageQuotesCardView
          TextImageQuotesCardViewData={TextImageQuotesCardViewData1}
        />
      </div>
      {/* <div className="mt-20">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data1}
        />
      </div> */}
      <div className="mt-70">
        <TextImageQuotesCardView
          TextImageQuotesCardViewData={TextImageQuotesCardViewData2}
        />
      </div>{" "}
      <div className="mt-20">
        <MiniImageBanner1
          id="two"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default ProductDevelopment;
